define(['app'], function (app) {
  const revieve = function () {
    let dataLayer = window.dataLayer;
    let locale = dataLayer[0].pageAttributes[0].locale.substr(0, 2);

    const getLastAddedItemData = function (simpleBasket) {
      let lastAddedItem = simpleBasket.lastAddedItem;

      let data = {
        worthValue: lastAddedItem.worthValue,
        productId: lastAddedItem.productId,
        productTitle: lastAddedItem.productTitle,
        productUrl: lastAddedItem.productURL,
        productQuantity: simpleBasket.lastAddedQuantity,
        productQuantityRequested: simpleBasket.lastAddedQuantityRequested,
        productPrice: lastAddedItem.price,
        basketTotalPrice: simpleBasket.totalprice,
        recommendations: simpleBasket.recommendations,
        basketTotalItems: simpleBasket.totalUnits,
        message: simpleBasket.message,
        lastAddedMaxPerOrder: lastAddedItem.maxPerOrder,
        loyaltySchemePoints: simpleBasket.loyaltySchemePoints
      };

      let imageURL = '';
      if (lastAddedItem.productImages && lastAddedItem.productImages.largeproduct) {
        imageURL = lastAddedItem.productImages.largeproduct;
      }
      data.productImageUrl = imageURL;
      return data;
    };

    const addToBasketSuccess = function (data) {
      app.publish('globalBasketItemsCount/updateGlobalBasketItemsCount');

      let jsonData = JSON.parse(data);
      let modalData = getLastAddedItemData(jsonData.simpleBasket);

      app.publish('productQuickbuy/hideModal', '', false);
      app.publish('addedToBasketModal/productAdded', modalData, false);
      app.publish('BasketItemAdded', jsonData);

      if (jsonData.simpleBasket.lastAddedItem) {
        let lastAddedItemSku = jsonData.simpleBasket.lastAddedItem.productId;
        if (window.dataLayer.length) {
          window.dataLayer[0].lastAddedItemSku = lastAddedItemSku.toString();
        };
      }
      return Promise.resolve();
    };

    const addToBasketError = function () {
      app.publish('productQuickbuy/stopLoading', '', false);
      app.publish('productQuickbuy/showError', '', false);
      app.publish('addedToBasketModal/showError', '', false);
    };

    const addToBasket = function (arrCount) {
      const item = productArray[arrCount];
      const queryObj = getQueryObject(item);
      if (queryObj && Object.keys(queryObj).length > 0) {

        const addToBasketData = _ => {
          return new Promise((resolve, reject) => {
            app.ajax.get({
              url: '/basketinterface.json?' + querify(queryObj),
              success: function (data) {
                addToBasketSuccess(data).then(function () {
                  if (arrCount < productArray.length - 1) {
                    arrCount++;
                    resolve(addToBasket(arrCount));
                  }
                });
              },
              error: function () {
                addToBasketError();
                if (arrCount < productArray.length - 1) {
                  arrCount++
                  reject(addToBasket(arrCount));
                }
              }
            });
          });
        }; addToBasketData();

      }
    }

    const querify = obj => Object.entries(obj).map(([key, value]) => `${key}=${value}`).join('&');

    const getQueryObject = function (item) {
      return {
        productId: item.id,
        siteId: siteObj.siteID,
        siteDefaultLocale: siteObj.siteDefaultLocale,
        siteSubsiteCode: siteObj.subsiteCode,
        variationLength: 0,
        quantity: 1,
        fromWishlist: false
      }
    };

    let productArray = [];
    let config = {
      partner_id: 'l91dWBKJkx',
      locale: locale,
      env: 'prod', // for test env. Change to prod for production deployment
      onAddToCart: function (products) {
        productArray = [];
        if (Array.isArray(products)) {
          products.forEach(function (product) {
            productArray.push(product);
          });
        } else {
          productArray.push(products);
        }
        addToBasket(0);
      },
      onClose: function () {
        // User closed modal. Here you could forward them to checkout if they had
        // added products (onAddToCart was called before).
      },
      onCheckout: function () {
        window.location.href = '/my.basket';
      },
      onClickProduct: function (product) {
        const win = window.open(product.url, '_blank');
        win.focus();
      },
    };

    (function () {
      const rv = document.createElement('script');
      rv.src = 'https://d38knilzwtuys1.cloudfront.net/revieve-plugin-v4/0.33.0/revieve-plugin-loader.js';
      rv.charset = 'utf-8';
      rv.type = 'text/javascript';
      rv.async = 'true';
      rv.integrity = 'sha384-Fgwh3vFBLiEdx2c6ic+cUSn/kPZpnvuLqV+YmGUor2qPQzr7f/Q6sf3ftPsnuzF2';
      rv.crossOrigin = 'anonymous';
      rv.onload = rv.onreadystatechange = function () {
        const rs = this.readyState;
        if (rs && rs != 'complete' && rs != 'loaded') return;
        Revieve.Init(config, function () {
          // Comment out the below line if you want to open the modal
          // manually when user clicks a certain button or navigates
          // to certain page.
          Revieve.API.show();
        });
      };
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(rv, s);
    })();
  }

  return revieve;
});
